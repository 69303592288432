import { Outlet } from "react-router-dom";
import CookieBanner from "./CookieBanner";
import Footer from "./Footer"

export default function Layout() {
    return (
        <>
          <CookieBanner />
          <Outlet />
          <Footer />
        </>
    )
}