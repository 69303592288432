import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export default function PopUpTry({handleClick}) {
    const popUpContainerRef = useRef(null)

    useEffect(() => {
        const popUpContainer = popUpContainerRef.current;
        gsap.from(popUpContainer, { opacity: 0, duration: .3, y: -30 });
    }, []);

    function handleUnmount(e) {
        const popUpContainer = popUpContainerRef.current;
        if(e.target !== e.currentTarget) return
        gsap.to(popUpContainer, { opacity: 0, duration: .1, y: -30, onComplete: () => {handleClick(e)} })
    }

    return (
        <div className="pop_up join_group_pop_up" onClick={handleUnmount}>
                <div className="pop_up_container join_group_pop_up_container" ref={popUpContainerRef}>
                    <p>Toni: Dodji u <span style={{textDecoration: 'underline'}}>Privatnu Facebook Grupu</span>
                    &nbsp;da prvi saznas cim objavim aplikaciju (uskoro)!!!</p>
                    <a href="https://www.facebook.com/groups/1470636126864890/?ref=share_group_link" id="facebook" className="social-network-click" target="_blank">
                        <img src={process.env.PUBLIC_URL + '/media/facebook_group.png'} alt="" />
                    </a>
                    <p>🎁 <span style={{textDecoration: 'underline'}}>Prvih 500</span> clanova dobija <span style={{textDecoration: 'underline'}}>10 voznji</span> besplatno!!!</p>
                </div>
        </div>
    )
}