import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import FacebookPixel from "./FacebookPixel";

export default function CookieBanner() {
    const [showBanner, setShowBanner] = useState(true)
    const [cookieConsent, setCookieConsent] = useState(false)
    const cookieBannerRef = useRef(null)


  
    useEffect(() => {
      if(window.localStorage.getItem('fbConsentStatus') === 'true') {
        setCookieConsent(true)
        hideCookieBanner()
      } 
    }, [])

  
    function hideCookieBanner() {
      const cookieBanner = cookieBannerRef.current
      gsap.to(cookieBanner, {y: -100, duration: .5, onComplete: () => setShowBanner(false)})
    }

    function handleAcceptCookies() {
      window.localStorage.setItem('fbConsentStatus', 'true')
      setCookieConsent(true)
      hideCookieBanner(); 
    }

    function handleRejectCookies() { 
      hideCookieBanner(); 
    }

    
    return (
        <>
          <FacebookPixel cookieConsent={cookieConsent}/>
          {showBanner && (
            <header className="header" id="cookie-banner" ref={cookieBannerRef}>
              <div className="header_container">
                <p className="header_text">Za bolju marketing verziju sajta ukljuci kolacice.</p>
                <button className="header_btn active" onClick={handleAcceptCookies} id="accept-cookies">Ukljuci</button>
                <button className="header_btn" onClick={handleRejectCookies} id="reject-cookies">Iskljuci</button>
              </div>
            </header>
          )}
        </>
    )
}