import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home'
import Info from './components/Info'
import GTMInitializer from './GTMInitializer'

function App() {

  return (
    <>
      <BrowserRouter>
        <GTMInitializer gtmId="GTM-N5VKS75V" />
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='app' element={<Info />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
