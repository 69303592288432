import React, { useState } from "react";
import PopUpPrivacyTerms from "./PopUpPrivacyTerms"; 

export default function Footer() {
    const [popUpPrivacyTerms, setPopUpPrivacyTerms] = useState(false)

    function handleClick() {
        setPopUpPrivacyTerms(!popUpPrivacyTerms)
    }

    return (
        <footer className="footer">
            <span>&#169; 2024 Papap | </span>
            <span style={{cursor: 'pointer'}} onClick={handleClick}>
              Privacy & Terms 
            </span>
            {popUpPrivacyTerms && <PopUpPrivacyTerms handleClick={handleClick}/>}
        </footer>
    )
}