import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PopUpTry from "./PopUpTry";
import PopUpPrice from "./PopUpPrice";

export default function Info() {
    const [popUpTry, setPopUpTry] = useState(false)
    const [popUpPrice, setPopUpPrice] = useState(false)
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    

    function handleTryClick(e) {
       setPopUpTry(!popUpTry)
    }

    function handlePriceClick(e) {
        setPopUpPrice(!popUpPrice)
    }


    return (
        <main>
          <div className="container">
            <Link to="/">
                <img className="logo_img" src={process.env.PUBLIC_URL + '/media/logo_horizontal.svg'} alt="" />
            </Link>
            <h2>🇷🇸🇧🇦🇭🇷🇲🇪🇸🇮🇲🇰</h2>
            <h2>&#9889; Neplanirane voznje.🏍️</h2>
            <h2>Veceras, sutra, vikend,</h2>
            <h2>&#128559; nikad ne znas.</h2>

            <img className="gif" src={process.env.PUBLIC_URL + '/media/gif.gif'} alt="" />

            <div className="info_list">
                <p>&#9889; Sve voznje na jednom mestu.</p>
                <p>&#9889; Stizu ti notifikacije da ne propustis.</p>
                <p>&#9889; Pridruzis se i vozis bez prestanka.</p>
            </div>

            <button 
              className="btn try_btn app-download-click" 
              id="iphone_btn" 
              onClick={handleTryClick} 
            >
                IPHONE &#9889; Hocu da probam!
            </button>
            <button 
              className="btn try_btn app-download-click" 
              id="android_btn"
              onClick={handleTryClick}
            >
                ANDROID &#9889; Hocu da probam!
            </button>
            <div className="price" id="price">
                <img src={process.env.PUBLIC_URL + '/media/gift_icon.png'} alt="" />
                <span>Prve 3 voznje su besplatne (jos uvek)!!!</span>
                <span style={{textDecoration: 'underline'}} className="price_btn" onClick={handlePriceClick}>Cena</span>
            </div>
            
            {popUpTry && <PopUpTry handleClick={handleTryClick}/>}
            {popUpPrice && <PopUpPrice handleClick={handlePriceClick}/>}

          </div>
        </main>
    )
}