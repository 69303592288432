import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';

export default function Home() {
    const [isVisible, setIsVisible] = useState(false)

    
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(!isVisible)
       }, 80000)
    }, [])

    return (
        <main>
          <div className="container">
              <Link to="/">
                  <img className="logo_img" src={process.env.PUBLIC_URL + '/media/logo_horizontal.svg'} alt="" />
              </Link>
              <h2>&#128071; Pogledaj Ceo Video &#128071;</h2>


              <div className="vimeo_container">
                <ReactPlayer
                  url="https://player.vimeo.com/video/918670479?h=31c1fabcc8"
                  controls
                  width="100%"
                  height="600px"
                  config={{
                    vimeo: {
                      playerOptions: {
                        fullscreen: false,
                        playsinline: true
                      }
                    }
                  }}
                />
              </div>

              <Link 
                to="/app" 
                className="btn join_group_btn" 
                style={{visibility: isVisible ? 'visible' : 'hidden'}}
              >
                  <img className="btn_img" src={process.env.PUBLIC_URL + '/media/logo_mark.svg'} alt="" />
                  <span style={{textDecoration: 'none'}}>Cekamo te unutra &#128073;</span>
              </Link>
          </div>
        </main>
    )
}