import React, { useRef, useEffect } from "react";
import gsap from "gsap";

export default function PopUpPrice({handleClick}) {
    const popUpContainerRef = useRef(null)
    
    useEffect(() => {
        const popUpContainer = popUpContainerRef.current;
        gsap.from(popUpContainer, { opacity: 0, duration: .3, y: -30 });
    }, []);

    function handleUnmount(e) {
        const popUpContainer = popUpContainerRef.current;
        if(e.target !== e.currentTarget) return
        gsap.to(popUpContainer, { opacity: 0, duration: .1, y: -30, onComplete: () => {handleClick(e)} })
    }

     
    return (
        <div className="pop_up price_pop_up" onClick={handleUnmount}>
                <div className="pop_up_container price_pop_up_container" ref={popUpContainerRef}>
                    <p>&#128241; <span style={{textDecoration: 'underline'}}>Aplikacija je besplatna!</span>
                    Nema mesecne naplate! Nema godisnje naplate!</p>
                    <p>&#128176; <span style={{textDecoration: 'underline'}}>Placa se 10€ da se pridruzis voznji koja ti se svidja.</span>
                    🎁 Prve 3 voznje su besplatne.</p>
                    <p>🚨 <span style={{textDecoration: 'underline'}}>Novac ne uzima kreator voznje,</span>
                    nego ga koristim da unapredim aplikaciju.&#9881;</p>
                </div>
        </div>
    )
}