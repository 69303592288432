import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

export default function FacebookPixel({cookieConsent}) {
    useEffect(() => {
        if(cookieConsent) {
            ReactPixel.init('7187578331302289');
            ReactPixel.pageView();
            ReactPixel.trackCustom('consent', { grant: true });
        }
    }, [cookieConsent])
}